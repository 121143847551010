<template>
  <div class="relative size-full overflow-hidden">
    <GoogleMap
      ref="mapRef"
      :center
      :api-key
      :zoom
      class="h-[calc(100%+48px)] select-none"
      language="en"
      version="weekly"
      :libraries="['places']"
      disable-default-ui
      @bounds_changed="handleBoundsChanged"
      @zoom_changed="handleZoomChanged"
    >
      <Marker
        v-if="centerByMarker"
        :options="
          getMarkerOptions({
            icon: '',
            lat: Number(centerByMarker.lat),
            lng: Number(centerByMarker.lng),
          })
        "
      />
      <Marker
        v-for="(property, index) in properties"
        v-else
        :key="index"
        :options="
          getMarkerOptions({
            lat: Number(property.latitude),
            lng: Number(property.longitude),
          })
        "
        @mouseover="showTooltip(property, $event)"
        @mouseout="removeTooltip"
        @click="navigateToProperty(property.complex_id)"
      />
    </GoogleMap>
    <PropertiesMapPopover
      v-if="reference && tooltipProperty"
      id="map_popover"
      :reference
      @mouseenter="resetTimeout"
      @mouseleave="removeTooltip"
    >
      <div class="flex min-h-[136px] min-w-[297px] max-w-[364px] gap-2 rounded-xl bg-white p-2 shadow-down">
        <img
          v-if="tooltipProperty.photo"
          :src="tooltipProperty.photo"
          width="160"
          height="120"
          class="h-[120px] w-[160px] cursor-pointer rounded-lg object-cover"
          alt="property"
          @click="navigateToProperty(tooltipProperty.complex_id)"
        />
        <div class="flex flex-col gap-2">
          <div v-if="usePropertiesTags(tags, tooltipProperty.tag_ids).length" class="flex flex-wrap gap-1">
            <div
              v-for="tag in usePropertiesTags(tags, tooltipProperty.tag_ids)"
              :key="tag.id"
              class="flex flex-row items-center gap-1 rounded-lg bg-additional-1-100 px-1.5 py-1 text-black-100"
            >
              <img width="16" height="16" :src="ICONS_LIST[tag.code as keyof typeof ICONS_LIST || '']" />
              <span class="text-caption">{{ tag.name }}</span>
            </div>
          </div>
          <div class="flex-col gap-2">
            <h4 class="text-subhead-1">{{ tooltipProperty.title.en }}</h4>
            <span class="text-body-2">{{ tooltipProperty.developer.en }}</span>
          </div>
        </div>
      </div>
    </PropertiesMapPopover>
  </div>
</template>

<script setup lang="ts">
import groupBy from 'lodash/groupBy'
import { GoogleMap, Marker } from 'vue3-google-map'
import type { ReferenceElement } from '@floating-ui/vue'
import { DICTIONARIES, PROPERTIES_SALE_STATUSES, PROPERTIES_TAGS_CODES } from '~/constants'
import type { LibraryItem, PropertyTypes } from '~/types'
import diamondSvg from '@/assets/icons/diamond.svg?url'
import { useAuthStore } from '~/store/auth'

const ICONS_LIST = {
  [PROPERTIES_TAGS_CODES.PROPERTY_EXCLUSIVE]: diamondSvg,
}

const emits = defineEmits(['filter-status', 'updated:position'])

const runtimeConfig = useRuntimeConfig()

const apiKey = computed(() => runtimeConfig.public.GOOGLE_MAPS_API_KEY)

type Props = {
  properties?: PropertyTypes.Property[]
  filters?: PropertyTypes.Filters
  zoom?: number
  centerByMarker?: google.maps.LatLngLiteral
  showStatuses?: boolean
  defaultMarker?: boolean
  showControls?: boolean
  showPropertyPopover?: boolean
  tags?: LibraryItem[]
}

const props = withDefaults(defineProps<Props>(), {
  filters: undefined,
  zoom: 10,
  centerByMarker: undefined,
  showControls: true,
  showPropertyPopover: false,
  tags: () => [],
  properties: () => [],
})

const isPropertiesVisible = ref(false)

const VISIBLE_EMIRATES_ZOOM = 9

const DUBAI_COORDINATES = { lat: 25.2048, lng: 55.2708 }

const EMIRATES_COORDINATES = [
  {
    name: 'Abu Dhabi',
    latitude: 24.4539,
    longitude: 54.3773,
  },
  {
    name: 'Dubai',
    latitude: DUBAI_COORDINATES.lat,
    longitude: DUBAI_COORDINATES.lng,
  },
  {
    name: 'Sharjah',
    latitude: 25.3463,
    longitude: 55.4209,
  },
  {
    name: 'Ajman',
    latitude: 25.4052,
    longitude: 55.5136,
  },
  {
    name: 'Umm Al Quwain',
    latitude: 25.5869,
    longitude: 55.5653,
  },
  {
    name: 'Fujairah',
    latitude: 25.1194,
    longitude: 56.3439,
  },
  {
    name: 'Ras Al Khaimah',
    latitude: 25.8007,
    longitude: 55.9762,
  },
]

type EmirateInfoWindow = {
  name: string
  id: string
  infoWindow: google.maps.InfoWindow
}

const emiratesInfoWindow = ref<EmirateInfoWindow[]>([])

const displayEmirates = () => {
  if (!mapObject.value) return

  const groupNames = Object.keys(groupPropertiesByEmirates(props.properties))

  EMIRATES_COORDINATES.forEach((emirate) => {
    const isEmirateInfoWindowExists = emiratesInfoWindow.value.find(({ name }) => name === emirate.name)

    if (isEmirateInfoWindowExists) return

    const id = `emirate-${emirate.name.replaceAll(' ', '-').toLowerCase()}`

    const content = `
      <div id="${id}" class="select-none">
        <style>
          button.gm-ui-hover-effect, .gm-style-iw-tc:after {
            display: none !important;
          }

          .gm-style-iw {
            box-shadow: none !important;
            border-radius: 0 !important;
            background: none !important;
          }

          .gm-style-iw, .gm-style-iw-ch, .gm-style-iw-d {
            padding: 0 !important;
            overflow: auto !important;
          }
        </style>
        <div class="flex items-center cursor-pointer bg-white gap-2 p-2 shadow-down border border-white rounded-lg hover:border-primary-50 transition-colors duration-300">
          <div class="size-8 rounded-lg bg-black-10 flex items-center justify-center">
            <img src="/oae-flag.webp" width="24" height="16" alt="emirate" />
          </div>
          <span class="text-subhead-2">${emirate.name}</span>
        </div>
      </div>
    `

    if (groupNames.includes(emirate.name)) {
      const infoWindow = new google.maps.InfoWindow({
        content,
        position: { lat: emirate.latitude, lng: emirate.longitude },
        disableAutoPan: true,
      })

      infoWindow.open(mapObject.value)

      emiratesInfoWindow.value.push({
        id,
        name: emirate.name,
        infoWindow,
      })

      nextTick(() => {
        const emirateDiv = document.querySelector(`#${id}`)

        if (!emirateDiv) return

        emirateDiv.addEventListener('click', () => {
          if (!mapObject.value) return

          mapObject.value.setCenter({ lat: emirate.latitude, lng: emirate.longitude })
          mapObject.value.setZoom(12)
        })
      })
    }
  })
}

const hideEmirates = () => {
  emiratesInfoWindow.value.forEach(({ infoWindow }) => {
    infoWindow.close()
  })

  emiratesInfoWindow.value = []
}

const mapRef = ref<InstanceType<typeof GoogleMap> | null>(null)

const mapObject = computed(() => {
  if (!mapRef.value?.ready) return null

  return mapRef.value.map
})

const handleMapChange = () => {
  if (!mapObject.value) return

  const zoom = mapObject.value.getZoom()

  if (!zoom) return

  isPropertiesVisible.value = zoom > VISIBLE_EMIRATES_ZOOM

  if (isPropertiesVisible.value) {
    hideEmirates()
  } else {
    displayEmirates()
  }
}

const groupPropertiesByEmirates = (properties: Props['properties']) => {
  if (!properties) return {}

  return groupBy(
    properties.filter((property) => property.emirate),
    (property) => property.emirate
  )
}

const handleBoundsChanged = () => {
  if (!mapObject.value) return

  const bounds = mapObject.value.getBounds()

  if (!bounds) return

  emits('updated:position', {
    topLeftLat: bounds.getNorthEast().lat(),
    topLeftLng: bounds.getSouthWest().lng(),
    bottomRightLat: bounds.getSouthWest().lat(),
    bottomRightLng: bounds.getNorthEast().lng(),
  })

  handleMapChange()

  removeTooltip()
}

const handleZoomChanged = () => {
  handleMapChange()

  removeTooltip()
}

const calculateMapCenter = () => {
  if (props.centerByMarker) {
    return props.centerByMarker
  }

  return DUBAI_COORDINATES
}

const center = ref(calculateMapCenter())

const statuses = ref<LibraryItem[]>([])

const infoWindow = ref<google.maps.InfoWindow | null>(null)

const addControls = async () => {
  if (!mapRef.value || !mapObject.value) return

  if (!props.showControls) return

  addControl(mapObject.value, 'zoom', String(google.maps.ControlPosition.LEFT_TOP))
  addControl(mapObject.value, 'center', String(google.maps.ControlPosition.LEFT_TOP))
  addControl(mapObject.value, 'mapType', String(google.maps.ControlPosition.LEFT_TOP))

  statuses.value = await useGetPropertiesLibrary(DICTIONARIES.PROPERTIES_SALE_STATUSES)

  if (props.showStatuses) {
    addStatusesButtons(mapObject.value)
  }

  infoWindow.value = new google.maps.InfoWindow()
}

watch(
  mapObject,
  () => {
    if (mapObject.value) {
      addControls()
      setupOverlay()
    }
  },
  {
    once: true,
  }
)

const createButton = (
  html: string,
  classes: string,
  ariaLabel: string,
  onClick: () => void,
  isActive?: boolean,
  onMouseOver?: () => void,
  onMouseOut?: () => void
) => {
  const button = document.createElement('button')

  button.innerHTML = html

  button.className = classes + (isActive ? 'bg-primary-90 text-white' : 'bg-white text-black-90') // Add 'active' class if isActive is true

  button.setAttribute('aria-label', ariaLabel)
  button.addEventListener('click', onClick)

  if (onMouseOver) {
    button.addEventListener('mouseover', onMouseOver)
  }

  if (onMouseOut) {
    button.addEventListener('mouseout', onMouseOut)
  }

  return button
}

const statusesButtonsColorsMap = {
  [PROPERTIES_SALE_STATUSES.ANNOUNCEMENT]: 'bg-secondary-120',
  [PROPERTIES_SALE_STATUSES.PRE_SALE]: 'bg-success-100',
  [PROPERTIES_SALE_STATUSES.LAUNCH]: 'bg-alert-100',
  [PROPERTIES_SALE_STATUSES.ON_SALE]: 'bg-primary-100',
  [PROPERTIES_SALE_STATUSES.SOLD_OUT]: 'bg-error-100',
}

const addStatusesButtons = (map: any) => {
  const buttonDiv = document.createElement('div')
  buttonDiv.id = 'status-buttons'
  buttonDiv.classList.add('flex', 'flex-row', 'justify-start', 'rounded-xl', 'm-2', 'md:m-[38px]', 'md:ml-6', 'gap-2')

  statuses.value
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((status) =>
      [
        PROPERTIES_SALE_STATUSES.ANNOUNCEMENT,
        PROPERTIES_SALE_STATUSES.PRE_SALE,
        PROPERTIES_SALE_STATUSES.LAUNCH,
        PROPERTIES_SALE_STATUSES.ON_SALE,
      ].includes(status.code as string)
    )
    .forEach((status) => {
      const button = createButton(
        `<span class="size-3 rounded-full ${statusesButtonsColorsMap[status?.code || '']}">
        </span>${status?.name || 'Unknown'}`,
        'bg-white  p-2 rounded-md flex justify-center shadow-lg text-caption md:text-subhead-4 flex-row gap-2 items-center ',
        status.name,
        () => {
          if (props.filters?.sale_status?.includes(Number(status.id))) {
            button.classList.remove('bg-primary-90')
            button.classList.remove('text-white')

            button.classList.add('bg-white')
            button.classList.add('text-black-90')
          } else {
            button.classList.remove('bg-white')
            button.classList.remove('text-black-90')

            button.classList.add('bg-primary-90')
            button.classList.add('text-white')
          }
          emits('filter-status', status.id)
        },
        props.filters?.sale_status?.includes(Number(status.id))
      )

      buttonDiv.appendChild(button)
    })

  map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(buttonDiv)
}

const controlsTypeMap = new Map([
  [
    'mapType',
    (map: google.maps.Map) => {
      const mapTypeControl = document.createElement('div')
      mapTypeControl.classList.add('flex', 'flex-row', 'mt-3', 'text-black-90')
      const mapButton = createButton(
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4232_82188)"><path d="M2 5.95715L12 9.5L22 5.95715L12 2.5L2 5.95715Z" stroke="currentColor" stroke-width="1.6" stroke-linejoin="round"/><path d="M2 10L12 13.5L22 10" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 14L12 17.5L22 14" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 18L12 21.5L22 18" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/></g></svg>',
        'bg-white p-2 rounded-xl !text-2xl h-10 flex justify-center shadow-lg',
        'Change map type',
        () => {},
        false,
        () => {
          // show popover with map and satellite options
          mapButton.classList.add('text-primary-90')
          mapButton.classList.remove('text-black-90')
          popover.classList.remove('hidden')
        },
        () => {
          // hide popover with map and satellite options after 1 sec, if the cursor is not on the button or the popover
          setTimeout(() => {
            if (
              !(
                x.value > mapButton.getBoundingClientRect().left &&
                x.value < mapButton.getBoundingClientRect().right &&
                y.value > mapButton.getBoundingClientRect().top &&
                y.value < mapButton.getBoundingClientRect().bottom
              ) &&
              !(
                x.value > popover.getBoundingClientRect().left &&
                x.value < popover.getBoundingClientRect().right &&
                y.value > popover.getBoundingClientRect().top &&
                y.value < popover.getBoundingClientRect().bottom
              )
            ) {
              popover.classList.add('hidden')
              mapButton.classList.remove('text-primary-90')
              mapButton.classList.add('text-black-90')
            }
          }, 1000)
        }
      )

      // Create the popover element
      const popover = document.createElement('div')
      popover.classList.add(
        'absolute',
        'left-14',
        'top-4',
        'w-[222px]',
        'h-[112px]',
        'bg-white',
        'shadow-lg',
        'rounded-lg',
        'hidden',
        'z-10',
        'flex',
        'flex-row',
        'gap-2',
        'p-4'
      )
      popover.innerHTML = `
        <div class="cursor-pointer hover:bg-gray-100 w-1/2 text-center text-subhead-4 text-subhead-4" id="mapTypeRoadmap">
        <img id="mapTypeRoadmapImage" src="/roadmap.webp" class="w-full h-[56px] rounded-lg border border-solid border-black-100"/>
        Map
        </div>
        <div class="cursor-pointer hover:bg-gray-100 w-1/2 text-center text-body-2" id="mapTypeSatellite">
        <img id="mapTypeSatelliteImage" src="/satellite.webp" class="w-full h-[56px] rounded-lg border border-solid border-black-70"/>Satellite</div>
      `

      // Add event listeners for the popover options
      const roadmapImage = popover.querySelector('#mapTypeRoadmapImage')
      const satelliteImage = popover.querySelector('#mapTypeSatelliteImage')
      const mapTypeRoadmap = popover.querySelector('#mapTypeRoadmap')
      const mapTypeSatellite = popover.querySelector('#mapTypeSatellite')
      if (!roadmapImage || !satelliteImage || !mapTypeRoadmap || !mapTypeSatellite) return
      mapTypeRoadmap.addEventListener('click', () => {
        map.setMapTypeId(google.maps.MapTypeId.ROADMAP)
        mapTypeRoadmap.classList.add('text-subhead-4')
        mapTypeRoadmap.classList.add('text-black-100')
        roadmapImage.classList.add('border-[1.5px]')
        roadmapImage.classList.add('border-black-100')
        mapTypeRoadmap.classList.remove('text-body-2')
        mapTypeRoadmap.classList.remove('text-black-60')
        roadmapImage.classList.remove('border')
        roadmapImage.classList.remove('border-black-70')

        mapTypeSatellite.classList.remove('text-subhead-4')
        mapTypeSatellite.classList.remove('text-black-100')
        satelliteImage.classList.remove('border-[1.5px]')
        satelliteImage.classList.remove('border-black-100')
        mapTypeSatellite.classList.add('text-body-2')
        mapTypeSatellite.classList.add('text-black-60')
        satelliteImage.classList.add('border')
        satelliteImage.classList.add('border-black-70')
        popover.classList.add('hidden')
      })

      mapTypeSatellite.addEventListener('click', () => {
        map.setMapTypeId(google.maps.MapTypeId.SATELLITE)
        mapTypeSatellite.classList.add('text-subhead-4')
        mapTypeSatellite.classList.add('text-black-100')
        satelliteImage.classList.add('border-[1.5px]')
        satelliteImage.classList.add('border-black-100')
        mapTypeSatellite.classList.remove('text-body-2')
        mapTypeSatellite.classList.remove('text-black-60')
        satelliteImage.classList.remove('border')
        satelliteImage.classList.remove('border-black-70')

        mapTypeRoadmap.classList.remove('text-subhead-4')
        mapTypeRoadmap.classList.remove('text-black-100')
        roadmapImage.classList.remove('border-[1.5px]')
        roadmapImage.classList.remove('border-black-100')
        mapTypeRoadmap.classList.add('text-body-2')
        roadmapImage.classList.add('text-black-60')
        roadmapImage.classList.add('border')

        popover.classList.add('hidden')
      })

      mapTypeControl.appendChild(mapButton)
      mapTypeControl.appendChild(popover)
      return mapTypeControl
    },
  ],
  [
    'zoom',
    (map) => {
      const zoomControl = document.createElement('div')
      zoomControl.classList.add('flex', 'flex-col', 'rounded-xl', 'w-10')

      const zoomInButton = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5V11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H11.25V19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19V12.75H19C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25H12.75V5Z" fill="currentColor"/></svg>',
        'bg-white p-2 rounded-t-md !text-2xl h-10 flex justify-center mt-3 shadow-lg',
        'Zoom in',
        () => map.setZoom((map.getZoom() || 0) + 1)
      )
      const zoomOutButton = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H19C19.4142 11.25 19.75 11.5858 19.75 12C19.75 12.4142 19.4142 12.75 19 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12Z" fill="currentColor"/></svg>',
        'bg-white p-2 rounded-b-md !text-2xl flex justify-center mb-3 shadow-lg',
        'Zoom out',
        () => map.setZoom((map.getZoom() || 0) - 1)
      )
      zoomControl.appendChild(zoomInButton)
      zoomControl.appendChild(zoomOutButton)
      return zoomControl
    },
  ],
  [
    'center',
    (map) => {
      const centerControl = document.createElement('div')
      centerControl.classList.add('flex', 'flex-col', 'rounded-xl', 'w-10')

      const button = createButton(
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_1812_17864)"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.63636C9.58909 7.63636 7.63636 9.58909 7.63636 12C7.63636 14.4109 9.58909 16.3636 12 16.3636C14.4109 16.3636 16.3636 14.4109 16.3636 12C16.3636 9.58909 14.4109 7.63636 12 7.63636ZM21.7527 10.9091C21.2509 6.36 17.64 2.74909 13.0909 2.24727V1.09091C13.0909 0.490909 12.6 0 12 0C11.4 0 10.9091 0.490909 10.9091 1.09091V2.24727C6.36 2.74909 2.74909 6.36 2.24727 10.9091H1.09091C0.490909 10.9091 0 11.4 0 12C0 12.6 0.490909 13.0909 1.09091 13.0909H2.24727C2.74909 17.64 6.36 21.2509 10.9091 21.7527V22.9091C10.9091 23.5091 11.4 24 12 24C12.6 24 13.0909 23.5091 13.0909 22.9091V21.7527C17.64 21.2509 21.2509 17.64 21.7527 13.0909H22.9091C23.5091 13.0909 24 12.6 24 12C24 11.4 23.5091 10.9091 22.9091 10.9091H21.7527ZM12 19.6364C7.77818 19.6364 4.36364 16.2218 4.36364 12C4.36364 7.77818 7.77818 4.36364 12 4.36364C16.2218 4.36364 19.6364 7.77818 19.6364 12C19.6364 16.2218 16.2218 19.6364 12 19.6364Z" fill="currentColor"/></g><defs><clipPath id="clip0_1812_17864"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>',
        'bg-white p-2 rounded-xl !text-2xl h-10 flex justify-center shadow-lg',
        'Center map',
        () => {
          map.setCenter(
            props.centerByMarker && props.properties.length > 0
              ? { lat: props.properties[0].latitude, lng: props.properties[0].longitude }
              : { lat: 25.2048, lng: 55.2708 }
          )
          map.fitBounds(
            new google.maps.LatLngBounds(
              new google.maps.LatLng(25.0948, 55.1608), // Slightly more southwest
              new google.maps.LatLng(25.3148, 55.3808) // Slightly more northeast
            )
          )
        }
      )
      centerControl.appendChild(button)
      return centerControl
    },
  ],
])

const addControl = (map: any, type: 'center' | 'zoom' | 'mapType', position: string) => {
  const controlDiv = document.createElement('div')
  controlDiv.classList.add('flex', 'flex-col', 'rounded-xl', 'mx-2', 'md:mx-6')

  const button = controlsTypeMap.get(type)?.(map)

  if (!button) return

  controlDiv.appendChild(button)
  map.controls[position].push(controlDiv)
}

const resetButtons = () => {
  const buttons = document.querySelectorAll('#status-buttons button')
  buttons.forEach((button) => {
    button.classList.remove('bg-primary-90')
    button.classList.remove('text-white')

    button.classList.add('bg-white')
    button.classList.add('text-black-90')
  })
}

const reference = ref<ReferenceElement | undefined>(undefined)

const tooltipProperty = ref<PropertyTypes.Property | null>(null)

const timeout = ref<ReturnType<typeof setTimeout> | null>(null)

const resetTimeout = () => {
  if (timeout.value) {
    clearTimeout(timeout.value)
  }
}

const removeTooltip = () => {
  timeout.value = setTimeout(() => {
    reference.value = undefined

    tooltipProperty.value = null
  }, 200)
}

// TODO: Move to MapPopover component
const overlay = ref<google.maps.OverlayView | null>(null)

const setupOverlay = () => {
  if (!mapObject.value || overlay.value) return

  overlay.value = new google.maps.OverlayView()
  overlay.value.onAdd = function () {} // Required but can be empty
  overlay.value.draw = function () {} // Required but can be empty
  overlay.value.onRemove = function () {} // Optional cleanup
  overlay.value.setMap(mapObject.value)
}

const getBoundingRectFromMarker = (lat: number, lng: number, markerWidth = 17, markerHeight = 17): DOMRect | null => {
  if (!mapObject.value || !overlay.value || !overlay.value.getProjection()) return null

  const projection = overlay.value.getProjection()
  const latLng = new google.maps.LatLng(lat, lng)

  const pixelCoords = projection.fromLatLngToContainerPixel(latLng)

  if (!pixelCoords) return null

  const mapContainer = mapObject.value.getDiv()
  const mapRect = mapContainer.getBoundingClientRect()

  const viewportX = mapRect.left + pixelCoords.x
  const viewportY = mapRect.top + pixelCoords.y

  const left = viewportX - markerWidth / 2
  const top = viewportY - markerHeight
  const right = left + markerWidth
  const bottom = top + markerHeight

  return {
    left,
    top,
    right,
    bottom,
    width: markerWidth,
    height: markerHeight,
    x: left,
    y: top,
    toJSON: () => null,
  }
}

const showTooltip = (property: PropertyTypes.Property, event: google.maps.MapMouseEvent) => {
  if (!props.showPropertyPopover || !event.latLng) {
    return
  }

  resetTimeout()

  const DOMRect = getBoundingRectFromMarker(event.latLng.lat(), event.latLng.lng())

  if (!DOMRect) return

  reference.value = {
    getBoundingClientRect: () => DOMRect,
  }

  tooltipProperty.value = property
}

const { x, y } = usePointer()

const getMarkerOptions = ({
  lat,
  lng,
  icon = '/map-marker.svg',
}: google.maps.LatLngLiteral & {
  icon?: string
}): google.maps.MarkerOptions => {
  return {
    map: mapObject.value,
    icon,
    position: isPropertiesVisible.value ? { lat, lng } : { lat: 0, lng: 0 },
  }
}

const authStore = useAuthStore()

const navigateToProperty = (id: PropertyTypes.Property['complex_id']) => {
  navigateTo(
    `/properties/${id}`,
    authStore.getVisitorToken
      ? undefined
      : {
          external: true,
          open: {
            target: '_blank',
          },
        }
  )
}

defineExpose({ resetButtons })
</script>

<style scoped></style>
